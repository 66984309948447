<template>
  <div>

    <!-- In progress -->
    <div v-if="inprogress" class="text-center">
      <h2>{{ inprogresstxt }}</h2>
      <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
    </div>

    <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
      <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close" v-on:click="closeModal">
        <i class="nc-icon nc-simple-remove"></i>
      </button>
      <span>{{ modal_alert_msg }}</span>
    </div>

    <div class="row" v-if="inprogress == false">
      <div class="col-md-5" style="margin-top: 15px">
        <p-button class="abit-up" round outline v-on:click="prevPage()">
          <i slot="label" class="nc-icon nc-minimal-left"></i>
          {{ $t('back') }}
        </p-button>
      </div>
      <div class="col-md-6" v-if="!followerview" style="margin: auto">
        <h5>{{ $t('account') }}: {{ account }} {{ $t('detail') }}</h5>
      </div>
      <div class="col-md-10" v-else>

      </div>
      <div class="col-md-1" v-if="!followerview">
        <el-tooltip :content="`${$t('analysisinfo_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
          <p-button type="info" round icon v-on:click="dlMathAnalysis()">
            <i slot="label" class="nc-icon nc-alert-circle-i"></i>
          </p-button>
        </el-tooltip>

      </div>
    </div>

    <!-- Charts -->
    <stats-charts :activeUsersChart="activeUsersChart" :equityUsersChart="equityUsersChart"
                  :inprogress="inprogress" :showdata="true" :eqReportingStatus="eqReportingStatus"
                  :balReportingStatus="balReportingStatus" :eqChartInfo="eqChartInfo" :balChartInfo="balChartInfo"
                  v-if="!followerview && eqReportingStatus && balReportingStatus">
    </stats-charts>
    <!-- Charts END -->

    <div class="row" v-if="inprogress == false">
      <div class="col-md-12 card">
        <div class="card-header">
          <h6>{{ $t('tradeopenhistory') }}</h6>
        </div>
        <div class="card-body row">

          <div class="col-sm-2">
            <label> {{ sortbyTxt }}</label>
            <el-select v-model="sortbyOpen.column" :placeholder="sortbyTxt"
              v-on:change="sortByOpenCol">
              <el-option class="select-default"
                v-for="item in sortbyOpen.columns"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ perpageTxt }}</label>
            <el-select v-model="paginationOpen.perPage" :placeholder="perpageTxt">
              <el-option class="select-default"
                v-for="item in paginationOpen.perPageOptions"
                :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ sortbyOrder }}</label>
            <el-select
                    v-model="sortbyOpen.order"
                    v-on:change="sortByOpenCol">
              <el-option class="select-default"
                         v-for="item in sortbyOpen.orders"
                         :key="item.prop"
                         :label="item.label"
                         :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> &nbsp </label>
            <div>
              <vue-excel-xlsx class="btn btn-info" style="margin: 0px;" :data="exportdata" :columns="exportcols" :filename="exportfilename" :sheetname="exportsheetname">
                  <i class="fa fa-download"></i> {{ $t('exportexcel') }}
              </vue-excel-xlsx>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="pull-right">
              <label> {{ searchtxt }}</label>
              <fg-input class="input-sm"
                        v-model="searchOpenQuery"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>

          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="queriedOpenData"
                      border
                      style="width: 100%">
              <el-table-column v-for="column in tableColumnsOpen"
                               :key="column.label"
                               :min-width="column.minWidth"
                               :align="column.align || 'center'"
                               :prop="column.prop"
                               :label="column.label">
              </el-table-column>

            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">{{ $t('showingtxt') }} {{fromOpen + 1}} {{ $t('totxt') }} {{toOpen}} {{ $t('oftxt') }} {{totalOpen}} {{ $t('entriestxt') }}</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationOpen.currentPage"
                          :per-page="paginationOpen.perPage"
                          :total="paginationOpen.total">
            </p-pagination>
          </div>
        </div>
      </div>

    </div>

    <div class="row" v-if="inprogress == false">
      <div class="col-md-12 card">
        <div class="card-header">
          <h6>{{ $t('tradehistory') }}</h6>
        </div>
        <div class="card-body row">

          <div class="col-sm-2">
            <label> {{ sortbyTxt }} </label>
            <el-select v-model="sortby.column" :placeholder="sortbyTxt"
              v-on:change="sortByCol">
              <el-option class="select-default"
                v-for="item in sortby.columns"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ perpageTxt }} </label>
            <el-select
              v-model="pagination.perPage" :placeholder="perpageTxt">
              <el-option class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ perdaystxt }} </label>
            <el-select
              v-model="pullby.perDays" v-on:change="getHistoryLoad">
              <el-option class="select-default"
                v-for="item in pullby.perDaysOptions"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ sortbyOrder }} </label>
            <el-select v-model="sortby.order"
                       v-on:change="sortByCol">
              <el-option class="select-default"
                v-for="item in sortby.orders"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> &nbsp </label>
            <div>
              <vue-excel-xlsx style="margin: 0px;" class="btn btn-info" :data="exportdatahist" :columns="exportcolshist" :filename="exportfilenamehist" :sheetname="exportsheetnamehist">
                  <i class="fa fa-download"></i> {{ $t('exportexcel') }}
              </vue-excel-xlsx>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="pull-right">
              <label> {{ searchtxt }} </label>
              <fg-input class="input-sm"
                        v-model="searchQuery"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>

          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="queriedData"
                      border
                      style="width: 100%">
              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth"
                               :align="column.align || 'center'"
                               :prop="column.prop"
                               :label="column.label">
              </el-table-column>

            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">{{ $t('showingtxt') }} {{from + 1}} {{ $t('totxt') }} {{to}} {{ $t('oftxt') }} {{total}} {{ $t('entriestxt') }}</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Tabs, TabPane, Card} from 'src/components/UIComponents'
  import {Tooltip, Table, TableColumn, Select, Option} from 'element-ui';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import StatsCharts from 'src/components/UIComponents/Charts/StatisticsCharts';
  import VueExcelXlsx from "vue-excel-xlsx";

  Vue.use(VueExcelXlsx)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  Vue.use(require('vue-moment'))

  const maxSteps = 10;
  const user_data = JSON.parse(localStorage.getItem('user-info'));
  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

  export default {
    components: {
      PPagination, StatsCard, StatsCharts, Card,
      [Tooltip.name]: Tooltip, TabPane, Tabs
    },
    computed: {
      pagedData () {
        return this.closed.slice(this.from, this.to)
      },
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.closed.length
          return this.pagedData
        }
        let result = this.closed
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.closed.length
        return this.closed.length
      },
      pagedOpenData () {
        return this.open.slice(this.fromOpen, this.toOpen)
      },
      queriedOpenData () {
        if (!this.searchOpenQuery) {
          this.paginationOpen.total = this.open.length
          return this.pagedOpenData
        }
        let result = this.open
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsOpenToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchOpenQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.paginationOpen.total = result.length
        return result.slice(this.fromOpen, this.toOpen)
      },
      toOpen () {
        let highBound = this.fromOpen + this.paginationOpen.perPage
        if (this.totalOpen < highBound) {
          highBound = this.totalOpen
        }
        return highBound
      },
      fromOpen () {
        return this.paginationOpen.perPage * (this.paginationOpen.currentPage - 1)
      },
      totalOpen () {
        this.paginationOpen.total = this.open.length
        return this.open.length
      }
    },
    data () {
      return {
        varicola: '',
        exportcols: [
         { label: this.$t('ordernum'), field: "orderNum" }, { label: this.$t('type'), field: "orderType" },
         { label: this.$t('ordersymbol'), field: "orderSymbol" }, { label: this.$t('orderlots'), field: "orderLots" },
         { label: this.$t('orderOpenTime'), field: "orderOpenTime" }, { label: this.$t('orderopenprice'), field: "orderOpenPrice" },
         { label: this.$t('ordercloseprice'), field: "orderClosePrice" }, { label: this.$t('orderswap'), field: "orderSwap" }
       ],
       exportdata: [], exportfilename: null, exportsheetname: this.$t('tradeopenhistory'),
       exportcolshist: [
         { label: this.$t('ordernum'), field: "ordernum" }, { label: this.$t('type'), field: "orderType" },
         { label: this.$t('ordersymbol'), field: "orderSymbol" }, { label: this.$t('eqorderlotsuity'), field: "orderLots" },
         { label: this.$t('orderOpenTime'), field: "orderOpenTime" }, { label: this.$t('orderopenprice'), field: "orderOpenPrice" },
         { label: this.$t('ordercloseprice'), field: "orderClosePrice" }, { label: this.$t('profit'), field: "orderProfit" },
         { label: this.$t('orderswap'), field: "orderSwap" }, { label: this.$t('ordercommission'), field: "orderCommission" },
         { label: this.$t('description'), field: "orderComment" }
        ],
        exportdatahist: [],
        exportfilenamehist: null,
        exportsheetnamehist: this.$t('tradehistory'),
        // Chart Data
        activeUsersChart: {
          labels: [],
          drawdown: {
            data: [],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function (value, index, values) {
                      return value + '%';
                    }
                  },
                  gridLines: {display: true}
                }],
                xAxes: [{
                  time: {unit: 'day', stepSize: 1},
                  type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                  gridLines: {display: true}
                }]
              }
            }
          },
          stats: {},
          percentualVolatilityEndurance: {
            data: [],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                    callback: function (value, index, values) {
                      return value + '%';
                    },
                    reverse: true
                  },
                  gridLines: {display: true}
                }],
                xAxes: [{
                  time: {unit: 'day', stepSize: 1},
                  type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                  gridLines: {display: true}
                }]
              }
            },
          },
          color: '#6bd098',
          accumulatedBalance: {
            datasets: [
              {
                borderColor: '#51cbce',
                backgroundColor: '#51cbce',
                data: [],
                pointRadius: 0,
                fill: false
              }
            ]
          },
          dailyBalanceGrowth: {
            datasets: [
              {
                borderColor: '#51cbce',
                backgroundColor: '#51cbce',
                data: [],
                pointRadius: 0,
                fill: false
              }
            ]
          },
          effectiveLeverageUsed: {
            data: [],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  gridLines: { display: true },
                }],
                xAxes: [{
                  time: { unit: 'day', stepSize: 1 },
                  type: 'time', gridLines: { display: true }
                }]
              }
            }
          },
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function(value, index, values) {
                    return value +'%';
                  }
                },
                gridLines: { display: true }
              }],
              xAxes: [{
                time: { unit: 'day', stepSize: 1 },
                type: 'time', ticks: { beginAtZero: true, stepSize: 1, maxTicksLimit: 10 },
                gridLines: { display: true }
              }]
            }
          },
          monthlyReturnBalance: {
            labels: [],
            datasets: [
              {
                borderColor: '#fcc468', fill: true,
                backgroundColor: '#fcc468', hoverBorderColor: '#fcc468',
                borderWidth: 8, data: []
              }
            ],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function(value, index, values) {
                      return value +'%';
                    }
                  },
                  gridLines: { display: true }
                }],
                xAxes: [{
                  gridLines: { display: true }
                }]
              }
            }
          }
        },
        equityUsersChart: {
          labels: [],
          effectiveDrawdown: {
            data: [],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function (value, index, values) {
                      return value + '%';
                    }
                  },
                  gridLines: {display: true}
                }],
                xAxes: [{
                  time: {unit: 'day', stepSize: 1},
                  type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                  gridLines: {display: true}
                }]
              }
            }
          },
          stats: {},
          percentualVolatilityEndurance: {
            data: [],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                    callback: function (value, index, values) {
                      return value + '%';
                    },
                    reverse: true
                  },
                  gridLines: {display: true}
                }],
                xAxes: [{
                  time: {unit: 'day', stepSize: 1},
                  type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                  gridLines: {display: true}
                }]
              }
            }
          },
          color: '#6bd098',
          effectiveLeverageUsed: {
            data: [],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  gridLines: { display: true },
                }],
                xAxes: [{
                  time: { unit: 'day', stepSize: 1 },
                  type: 'time', gridLines: { display: true }
                }]
              }
            }
          },
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function(value, index, values) {
                    return value +'%';
                  }
                },
                gridLines: { display: true }
              }],
              xAxes: [{
                time: { unit: 'day', stepSize: 1 },
                type: 'time', ticks: { beginAtZero: true, stepSize: 1, maxTicksLimit: 10 },
                gridLines: { display: true }
              }]
            }
          },
          monthlyReturnEquity: {
            labels: [],
            datasets: [
              {
                borderColor: [], fill: true,
                backgroundColor: [], hoverBorderColor: [],
                borderWidth: 8, data: [], fillColor: []
              }
            ],
            options: {
              tooltips: tooltipOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function(value, index, values) {
                      return value +'%';
                    }
                  },
                  gridLines: { display: true }
                }],
                xAxes: [{
                  gridLines: { display: true }
                }]
              }
            }
          },
          accumulatedEquityGrowth: {
            datasets: [
              {
                borderColor: '#51cbce',  backgroundColor: '#51cbce',
                data: [], pointRadius: 0, fill: false
              }
            ]
          },
          dailyEquityGrowth: {
            datasets: [
              {
                borderColor: '#51cbce',  backgroundColor: '#51cbce',
                data: [], pointRadius: 0, fill: false
              }
            ]
          }
        },
        balChartInfo: {},
        eqChartInfo: {},
        balReportingStatus: null,
        eqReportingStatus: null,
        isbroker: false,
        account: null,
        inprogress: true,
        adminusr: false,
        closed: [],
        open: [],
        sortbyTxt: this.$t('sortby'),
        perpageTxt: this.$t('perpage'),
        searchtxt: this.$t('searchtxt'),
        perdaystxt: this.$t('perdays'),
        pollhistory: null,
        ueml: null,
        aid: null,
        statsCards: [],
        manydays: 30,
        modal: false,
        modal_alert: false,
        modal_alert_msg: null,
        modal_alert_type: 'danger',
        followerview: false,
        inprogresstxt: this.$t('loadingaccountdeets'),
        sortbyOrder: this.$t('sortbyorder'),
        sortbyOpen: {
          column: 'orderOpenTime',
          columns: [
            {
              prop: 'orderNum',
              label: this.$t('ordernum')
            },
            {
              prop: 'orderType',
              label: this.$t('type')
            },
            {
              prop: 'orderSymbol',
              label: this.$t('ordersymbol')
            },
            {
              prop: 'orderLots',
              label: this.$t('orderlots')
            },
            {
              prop: 'orderOpenTime',
              label: this.$t('orderopentime')
            },
            {
              prop: 'orderOpenPrice',
              label: this.$t('orderopenprice')
            },
            {
              prop: 'orderClosePrice',
              label: this.$t('ordercloseprice')
            },
            {
              prop: 'orderSwap',
              label: this.$t('orderswap')
            }
          ],
          order: 'asc',
          orders: [
              {prop: 'asc', label: this.$t('ascending')},
              {prop: 'desc', label: this.$t('descending')}
          ],
        },
        sortby: {
          order: 'asc',
          orders: [
              {prop: 'asc', label: this.$t('ascending')},
              {prop: 'desc', label: this.$t('descending')}
          ],
          column: 'orderCloseTime',
          columns: [
            {
              prop: 'ordernum',
              label: this.$t('ordernum')
            },
            {
              prop: 'orderType',
              label: this.$t('type')
            },
            {
              prop: 'orderSymbol',
              label: this.$t('ordersymbol')
            },
            {
              prop: 'orderLots',
              label: this.$t('orderlots')
            },
            {
              prop: 'orderOpenTime',
              label: this.$t('orderopentime')
            },
            {
              prop: 'orderCloseTime',
              label: this.$t('orderclosetime')
            },
            {
              prop: 'orderOpenPrice',
              label: this.$t('orderopenprice')
            },
            {
              prop: 'orderClosePrice',
              label: this.$t('ordercloseprice')
            },
            {
              prop: 'orderProfit',
              label: this.$t('profit')
            },
            {
              prop: 'orderSwap',
              label: this.$t('orderswap')
            },
            {
              prop: 'orderCommission',
              label: this.$t('ordercommission')
            },
            {
              prop: 'orderComment',
              label: this.$t('description')
            }
          ]
        },
        sortOrderType:'',
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        pullby: {
          perDays: 30,
          perDaysOptions: [
            { prop: 'all', label: this.$t('alltxt') },
            { prop: 30, label: "1 "+ this.$t('monthtxt') },
            { prop: 180, label: "6 "+ this.$t('monthtxt') },
            { prop: 365, label: "1 "+ this.$t('yeartxt') }
          ]
        },
        paginationOpen: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '', searchOpenQuery: '',
        propsToSearch: ['ordernum', 'orderType', 'orderSymbol', 'orderComment'],
        propsOpenToSearch: ['orderNum', 'orderType', 'orderSymbol'],
        tableColumnsOpen: [
          {
            prop: 'orderNum',
            label: this.$t('ordernum')
          },
          {
            prop: 'orderType',
            label: this.$t('type'),
            minWidth: '40px'
          },
          {
            prop: 'orderSymbol',
            label: this.$t('ordersymbol')
          },
          {
            prop: 'orderLots',
            label: this.$t('orderlots'),
            minWidth: '40px'
          },
          {
            prop: 'orderOpenTime',
            label: this.$t('orderopentime')
          },
          {
            prop: 'orderOpenPrice',
            label: this.$t('orderopenprice')
          },
          {
            prop: 'orderClosePrice',
            label: this.$t('ordercloseprice')
          },
          {
            prop: 'orderSwap',
            label: this.$t('orderswap')
          },
          {
            prop: 'orderProfit',
            label: this.$t('profit')
          },
        ],
        tableColumns: [
          {
            prop: 'ordernum',
            label: this.$t('ordernum')
          },
          {
            prop: 'orderType',
            label: this.$t('type'),
            minWidth: '40px'
          },
          {
            prop: 'orderSymbol',
            label: this.$t('ordersymbol')
          },
          {
            prop: 'orderLots',
            label: this.$t('orderlots'),
            minWidth: '40px'
          },
          {
            prop: 'orderOpenTime',
            label: this.$t('orderopentime'),
            minWidth: '100px'
          },
          {
            prop: 'orderCloseTime',
            label: this.$t('orderclosetime'),
            minWidth: '100px'
          },
          {
            prop: 'orderOpenPrice',
            label: this.$t('orderopenprice')
          },
          {
            prop: 'orderClosePrice',
            label: this.$t('ordercloseprice')
          },
          {
            prop: 'orderSwap',
            label: this.$t('orderswap')
          },
          {
            prop: 'orderCommission',
            label: this.$t('ordercommission')
          },
          {
            prop: 'orderProfit',
            label: this.$t('profit')
          },
        ]
      }
    },
    methods: {
      closeModal() {
        this.modal_alert = false;
        this.modal_alert_msg = null;
        this.modal_alert_type = 'danger';
      },
      dlMathAnalysis() {
        let request = new XMLHttpRequest();

        const stateChanged = e => {
          if (request.readyState == 4) {
            window.open('/static/docs/HokoCloud-AnalysisMathematics.pdf', '_top');
          }
        };

        request.addEventListener('readystatechange', stateChanged);

        request.addEventListener('progress', function(e) {
        	var percent_complete = (e.loaded / e.total)*100;
        });

        request.responseType = 'blob';

        // Downloading Doc
        request.open('get', '/static/docs/HokoCloud-AnalysisMathematics.pdf');
        request.send();
      },
      getAccountDeet(resp) {
        if(!resp.success) {
          this.$toast.warning(resp.msg);
          return
        }
        this.account = resp.account;
        this.closed = [];
        for (let j=0;j < resp.order_history.length;j++) {
          let oh = resp.order_history[j];
          oh['orderCloseTime'] = this.$moment(oh['orderCloseTime']*1000).format("YYYY-MM-DD HH:mm:ss");
          oh['orderOpenTime'] = this.$moment(oh['orderOpenTime']*1000).format("YYYY-MM-DD HH:mm:ss");

          this.closed.push(oh);
        }
        this.exportdatahist = this.closed.slice(0);
        this.exportfilenamehist = "TradeHistory_Closed-AsOf-"+ this.pullby.perDays +"DaysAgo";

        this.open = [];
        for(let j=0;j < resp.orders_open.length;j++) {
          let oph = resp.orders_open[j];
          oph['orderCloseTime'] = this.$moment(oph['orderCloseTime']*1000).format("YYYY-MM-DD HH:mm:ss");
          oph['orderOpenTime'] = this.$moment(oph['orderOpenTime']*1000).format("YYYY-MM-DD HH:mm:ss");

          this.open.push(oph);
        }
        this.exportdata = this.open.slice(0);
        this.exportfilename = "TradeHistory_Open-"+ this.$moment('timezone', 'Europe/London', 'LLLL ss').format("YYYY-MM-DD_HHmmss");

        // Account Details
        if (this.followerview) {
          this.inprogress = false;
        } else {
          this.$getAccountDetails(this.aid)
            .then(this.loadedDetails, this.failop);
        }
      },
      getHistoryLoad() {
        this.inprogress = true;
        let _days = this.pullby.perDays;
        let folv = false;
        if (_days == 'all') {
          _days = null;
        }
        if (this.followerview && !this.isbroker) {
          folv = true;
        }
        this.$getAccountHistory(this.aid, _days, folv)
          .then(this.getAccountDeet, this.failop);
      },
      loadedEqPlot(resp) {
        if (!resp.success) {
          this.inprogress = false;
          return;
        }
        let data = resp.data;

        let today = this.$moment('timezone', 'Europe/London', 'LLLL ss').format('YYYY-MM-DD');

        if (data.plot) {
          for (var i=0; i < data.plot.length; i++) {
            let p = data.plot[i];
            let dtday = this.$moment(p.unix_dt * 1000).format("YYYY-MM-DD");
            // Exclude today's
            if (today !== dtday) {
              this.equityUsersChart.labels.push(p.unix_dt * 1000);
              this.equityUsersChart.accumulatedEquityGrowth.datasets[0].data.push(p.accumulatedEquityGrowth * 100);
              this.equityUsersChart.dailyEquityGrowth.datasets[0].data.push(p.dailyEquityGrowth * 100);
              this.equityUsersChart.effectiveDrawdown.data.push(p.effectiveDrawdown * 100);
              this.equityUsersChart.effectiveLeverageUsed.data.push(p.effectiveLeverageUsed);
              if (i > 0) {
                this.equityUsersChart.percentualVolatilityEndurance.data.push(p.percentualVolatilityEndurance * 100);
              }
            }
            //monthlyReturnBalance
            if (p.monthlyReturnEquity !== null) {
              let mo = this.$moment(p.unix_dt * 1000).format("MMM YY");
              //this.activeUsersChart.monthlyReturnBalance.labels.push(p.unix_dt * 1000);
              this.equityUsersChart.monthlyReturnEquity.labels.push(mo);
              const moReturnEq = p.monthlyReturnEquity * 100;
              let mainColor = '#51cbce';
              if (moReturnEq < 0) {
                mainColor = '#ef8157';
              }
              this.equityUsersChart.monthlyReturnEquity.datasets[0].data.push(moReturnEq);
              this.equityUsersChart.monthlyReturnEquity.datasets[0].borderColor.push(mainColor);
              this.equityUsersChart.monthlyReturnEquity.datasets[0].backgroundColor.push(mainColor);
              this.equityUsersChart.monthlyReturnEquity.datasets[0].hoverBorderColor.push(mainColor);
              //const lastidx = this.equityUsersChart.monthlyReturnEquity.datasets[0].data.length - 1;
              //this.equityUsersChart.monthlyReturnEquity.datasets[0].bars[lastidx].fillColor = mainColor;
            }
          }
        }

        // Modify Step Size
        if (this.equityUsersChart.labels.length > maxSteps) {
          let stepSize = parseInt(this.equityUsersChart.labels.length / maxSteps);
          this.equityUsersChart.options.scales.xAxes[0].time.stepSize = stepSize;
          this.equityUsersChart.effectiveLeverageUsed.options.scales.xAxes[0].time.stepSize = stepSize;
        }
        // Statistics
        this.equityUsersChart.stats = Object.assign({}, data.stats);
        // Chart Info
        this.eqChartInfo = {
          "lastupdate": this.$t('lastupdate') +": "+ data.lastupdate,
          "orders_fail": data.orders_fail,
          "symbol_price_fail": data.symbol_price_fail,
          "trade_history_fail": data.trade_history_fail
        };
        this.eqReportingStatus = this.$getChartFlagInfo(this.balChartInfo);
        // Store into Local Storage
        localStorage.setItem('hc_bal_strat_info_'+ data.account, JSON.stringify({
          activeUsersChart: Object.assign({}, this.activeUsersChart),
          balChartInfo: Object.assign({}, this.balChartInfo),
          balReportingStatus: this.balReportingStatus,
          equityUsersChart: Object.assign({}, this.equityUsersChart),
          eqChartInfo: Object.assign({}, this.eqChartInfo),
          eqReportingStatus: this.eqReportingStatus,
          lastupdated: this.$moment('timezone', 'Europe/London', 'LLLL ss').add(1, 'h').unix()
        }));
        // Show Data
        this.inprogress = false;
      },
      loadedPlot(resp, account) {
        if (!resp.success) {
          return;
        }

        let data = resp.data;
        let today = this.$moment('timezone', 'Europe/London', 'LLLL ss').format('YYYY-MM-DD');

        if (data.plot) {
          for (let i=0;i < data.plot.length;i++) {
            let p = data.plot[i];
            let dtday = this.$moment(p.unix_dt * 1000).format("YYYY-MM-DD");
            // Exclude today's
            if (today !== dtday) {
              this.activeUsersChart.labels.push(p.unix_dt * 1000);
              this.activeUsersChart.accumulatedBalance.datasets[0].data.push(p.accumulatedBalance * 100);
              this.activeUsersChart.dailyBalanceGrowth.datasets[0].data.push(p.dailyBalanceGrowth * 100);
              this.activeUsersChart.drawdown.data.push(p.drawdown * 100);
              this.activeUsersChart.effectiveLeverageUsed.data.push(p.effectiveLeverageUsed);
              if (i > 0) {
                this.activeUsersChart.percentualVolatilityEndurance.data.push(p.percentualVolatilityEndurance * 100);
              }
            }
            //monthlyReturnBalance
            if (p.monthlyReturnBalance !== null) {
              let mo = this.$moment(p.unix_dt * 1000).format("MMM YY");
              this.activeUsersChart.monthlyReturnBalance.labels.push(mo);
              this.activeUsersChart.monthlyReturnBalance.datasets[0].data.push(p.monthlyReturnBalance * 100);
            }
          }
        }

        // Modify Step Size
        if (this.activeUsersChart.labels.length > maxSteps) {
          let stepSize = parseInt(this.activeUsersChart.labels.length / maxSteps);
          this.activeUsersChart.options.scales.xAxes[0].time.stepSize = stepSize;
          this.activeUsersChart.effectiveLeverageUsed.options.scales.xAxes[0].time.stepSize = stepSize;
        }
        // Statistics
        this.activeUsersChart.stats = Object.assign({}, data.stats);
        // Chart Info
        this.balChartInfo = {
          "lastupdate": this.$t('lastupdate') +": "+ data.lastupdate,
          "orders_fail": data.orders_fail,
          "symbol_price_fail": data.symbol_price_fail,
          "trade_history_fail": data.trade_history_fail
        };
        this.balReportingStatus = this.$getChartFlagInfo(this.balChartInfo);
        // Get Equity Plot
        this.$getPlotDataEquity(account)
          .then(this.loadedEqPlot, this.failop);
      },
      loadedDetails(resp) {
        this.statsCards = [];
        if (resp.data.status.length > 0) {
          const status = resp.data.status[0];
          const update = this.$moment(status['last_update']*1000).format("YYYY-MM-DD HH:mm:SS");
        }

        const preLoadedPlot = (r, stratdata) => {
          this.loadedPlot(r, stratdata);
        };

        if (this.pollaccs === null || this.pollaccs === undefined) {
          // this.pollaccs = setInterval(this.getHistoryLoad, 30000);
          // Get Plot Data
          // Check local storage before loading strategy
          let now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();
          let loadFromLocalStorage = false;
          let chartinfo = localStorage.getItem('hc_bal_strat_info_'+ resp.data.account);
          if (chartinfo !== undefined && chartinfo !== null) {
            chartinfo = JSON.parse(chartinfo);
            if (now <= chartinfo.lastupdated) {
              loadFromLocalStorage = true;
              this.activeUsersChart = Object.assign({}, chartinfo.activeUsersChart);
              this.activeUsersChart.options.scales.yAxes[0].ticks = {
                callback: function(value, index, values) {
                  return value +'%';
                }
              };
              this.balChartInfo = Object.assign({}, chartinfo.balChartInfo);
              this.balReportingStatus = chartinfo.balReportingStatus;

              this.equityUsersChart = Object.assign({}, chartinfo.equityUsersChart);
              this.equityUsersChart.options.scales.yAxes[0].ticks = {
                callback: function(value, index, values) {
                  return value +'%';
                }
              };
              this.eqChartInfo = Object.assign({}, chartinfo.eqChartInfo);
              this.eqReportingStatus = chartinfo.eqReportingStatus;
              // Show Data
              this.inprogress = false;
              this.showdata = true;
            }
          }
          // Load from API
          if (!loadFromLocalStorage) {
            this.$getPlotData(resp.data.account).then(function(r) {
              preLoadedPlot(r, resp.data.account);
            }, this.failop);
          }
        }
        else {
          this.inprogress = false;
        }
      },
      sortItNum (a, b) {
        if (this.sortOrderType == 'asc') {
            return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortOrderType == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol(col) {
        this.sortOrderType = this.sortby.order
        this.colSort = this.sortby.column;
        if (this.colSort == 'orderClosePrice' || this.colSort == 'orderOpenPrice' || this.colSort == 'orderLots' || this.colSort == 'orderCommission'
            || this.colSort == 'orderProfit' || this.colSort == 'orderSwap') {
          this.closed.sort(this.sortItNum);
        }
        else {
          this.closed.sort(this.sortIt);
        }
      },
      sortByOpenCol(col) {
        this.sortOrderType = this.sortbyOpen.order
        this.colSort = this.sortbyOpen.column;
        if (col == 'orderClosePrice' || col == 'orderOpenPrice' || col == 'orderLots' || col == 'orderCommission'
            || col == 'orderProfit' || col == 'orderSwap') {
          this.open.sort(this.sortItNum);
        }
        else {
          this.open.sort(this.sortIt);
        }
      },
      failop (error) {
        if (error === "Mismatched UID and AID") {
          this.$toast.warning(this.$t('notallowedtoseehistory'));
          this.inprogress = false;
          return;
        }
        if (error && error.data && error.data.msg && error.data.msg == 'Division by 0') {
          // This error means the account has no data
          this.modal_alert = false;
          this.modal_alert_msg = null;
          return;
        }
        if (error && error['status'] === 500) {
          error = error.statusText;
        } else {
          error = this.$t('failtoretrieveaccountdetails');
        }
        this.varicola = this.$moment(1716845327).format("YYYY-MM-DD HH:mm:ss");

        // this.$toast.error(error);
        this.inprogress = false;
      },
      prevPage() {
        window.history.back();
      }
    },
    mounted () {
      // Admin User or NOT
      if (user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }
      if (user_data['roles'].indexOf('broker') >= 0) {
        this.isbroker = true;
      }

      this.ueml = this.$route.params.userid;
      this.aid = this.$route.params.accid;

      if (this.ueml === user_data.email || this.isbroker || this.adminusr || (this.ueml == 'historical')) { // && (this.aid == undefined && this.aid == null))) {
        if (this.ueml == 'historical') {
          this.followerview = true;
        }
        this.getHistoryLoad();
      }
      else {
        this.failop("Mismatched UID and AID");
      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
    destroyed() {
      clearInterval(this.pollhistory);
      clearInterval(this.pollaccs);
    }
  }
</script>
<style lang="scss">
  .abit-up {
    position: relative;
    top: -15px;
  }
  .abit-down {
    position: relative;
    top: 2px;
  }
  .adjust-width {
    width: 325px;
  }
  .first-col {
    width: 25%;
  }
  .label-stat {
    text-transform: uppercase;
    font-weight: bold;
  }
  .pad-me-right {
    padding-right: 40px !important;
    width: 25%
  }
  .table {
    margin-bottom: 0rem;
  }
  .table-responsive {
    overflow: hidden;
  }
</style>
